import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../../layouts/Header';
import MainMenu from '../../layouts/MainMenu';
import Footer from '../../layouts/Footer';

import { db } from '../../../common/firebase';
import {
	getDoc,
    doc
} from "firebase/firestore";

const BlogPage = () => {
	const { id } = useParams();

	const [ blog, setBlog ] = useState(null);
	const [ loading, setLoading ] = useState(true);

	const getBlog = async () => {
        const docRef = doc(db, "posts", id);
        const docSnap = await getDoc(docRef);

        let post = null;
        if (docSnap.exists()) {
            post = docSnap.data();
        }

        setBlog(post);
        
		setTimeout(() => {
            setLoading(false);
        }, 500);
    }

	useEffect(() => {
		getBlog();
	}, []);

	return (
		<div className="page page-blogs">

			<Header />

			<div className="content">
				<div className="content-wrapper">

					<MainMenu />

					<div>
						
						{loading ? (
							<div className="loading-wrapper">

								<div className="loading">
									<div></div><div></div>
								</div>
								
							</div>
						) : (

							<>
								<h1 className="page-title">Blog - {blog.title}</h1>

								<div className="blogs-wrapper">
									<p>{blog.body}</p>
								</div>

							</>
						)}

					</div>

				</div>
			</div>

			<Footer />

		</div>
	)
}

export default BlogPage;