import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import MainMenu from "../layouts/MainMenu";

const CreditsPage = () => {

    const heartImage = require('../../images/heart.png');

    return (
        <>
            <div className="page page-credits">

                <Header />

                <div className="content">
                    <div className="content-wrapper">

                        <MainMenu />

                        <h1 className="page-title">Credits</h1>

                        <p>Thank you for visiting this page.</p>

                        <p>Spread the love and support to this page by sharing this page to your friends and family.</p>

                        <img src={heartImage} alt="thankyou" className="heart-icon" />

                        <hr />

                        <h4>Resources credits</h4>

                        <a target="_blank" href="https://www.flaticon.com/free-icons/heart" title="heart icons">Heart icons created by Freepik - Flaticon</a>

                    </div>
                </div>

                <Footer />
            
            </div>
        </>
    )
}

export default CreditsPage;