import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import MainMenu from '../../layouts/MainMenu';
import { ImageWithFadeIn } from '../../../common/ImageWithFadeIn';

import { db } from '../../../common/firebase';
import {
    getDocs,
    collection, query, where
} from "firebase/firestore";

import { getStorage, getDownloadURL, ref } from "firebase/storage";

const Blogs = () => {
    let navigate = useNavigate();

    const [ blogs, setBlogs ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const handlePostClick = (post) => {
        navigate('/blogs/' + post.id);
    }

    const blogMapped = blogs.map((blog, key) => {

        const handleClick = () => {
            handlePostClick(blog);
        }

        return (
            <Item key={key} blog={blog} handleClick={handleClick} />
        )
    });

    const getPosts = async () => {
        const q = query(collection(db, "posts"), where("status", "==", true));
        const snapshot = await getDocs(q);
        let posts = snapshot.docs.map(doc => {
            let temp = doc.data();
            temp.id = doc.id;
            return temp
        });

        setBlogs(posts);
        
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    useEffect(() => {
        getPosts();
    }, []);

    return (
        <>
            <div className="page page-blogs">

                <Header />

                <div className="content">
                    <div className="content-wrapper">

                        <MainMenu />

                        <div>
                            <h1 className="page-title">Blogs</h1>

                            {loading ? (
                                <div className="loading-wrapper">

                                    <div className="loading">
                                        <div></div><div></div>
                                    </div>
                                    
                                </div>
                            ) : (
                                <div className="blogs-wrapper">
                                    { blogMapped }
                                </div>
                            )}

                        </div>

                    </div>
                </div>

                <Footer />

            </div>
        </>
    )
}

const Item = ({ blog, handleClick }) => {
    
    return (
        <div className="blog-item" onClick={handleClick}>
            <div className="left-side">
                <ImageWithFadeIn src={blog.image} alt={blog.title} />
            </div>
            <div className="right-side">
                <div className="title-section">
                    <h3>{ blog.title }</h3>
                    <p>{ blog.date }</p>
                </div>
                <div className="content-section">
                    <p>{ blog.summary }</p>
                    <p>[ Read more ]</p>
                </div>
            </div>
        </div>
    )
}


export default Blogs;