// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import {
    getFirestore, query, getDocs,
    collection, where, addDoc
} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDMQ-BeHmhbY8W-L3XlsXHKFWkwW3u24x0",
  authDomain: "mylimbuculture.firebaseapp.com",
  projectId: "mylimbuculture",
  storageBucket: "mylimbuculture.appspot.com",
  messagingSenderId: "718455074553",
  appId: "1:718455074553:web:6d9a40bed9353252857c28",
  measurementId: "G-LWQF3XLJ09"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export {
  app,
  db
}