import React, { useState } from 'react';

export const ImageWithFadeIn = ({ src, alt }) => {
    const [loaded, setLoaded] = useState(false);

    const handleImageLoaded = () => {
        setLoaded(true);
    };

    return (
        <div className={`image-container ${loaded ? 'loaded' : ''}`}>
            <img
            src={src}
            alt={alt}
            onLoad={handleImageLoaded}
            />
        </div>
    );
}