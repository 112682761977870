import { useEffect, useState } from "react";

import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import MainMenu from "../layouts/MainMenu";

import { db } from '../../common/firebase';
import {
    getDocs, collection
} from "firebase/firestore";

const InfoGraphPage = () => {
    
    const [ events, setEvents ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const timeline = events.map((item, key) => {
        const side = (key%2 == 0) ? 'left' : 'right';
        return (
            <TimelineItem key={key} data = {item} classSide={side} />
        )
    });

    const getEvents = async () => {
        const ref = collection(db, 'timeline');
        const snapshot = await getDocs(ref);
        let data = snapshot.docs.map(doc => doc.data());

        setEvents(data);
        
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    useEffect(() => {
        getEvents();
    }, []);

    return (
        <>
            <div className="page">

                <Header />

                <div className="content">
                    <div className="content-wrapper">

                        <MainMenu />

                        <h1 className="page-title">Timeline</h1>

                        <p>This page shows the events that occured in the time important to Kirat History. The order of events is in ascending order, the oldest at top and the latest in the bottom.</p>
                        <p>Feel free to email me about information you may think is important to show in this page.</p>

                        <div className="space-block"></div>

                        <div className="timeline-wrapper">

                            {loading ? (
                                <div className="loading-wrapper">

                                    <div className="loading">
                                        <div></div><div></div>
                                    </div>
                                    
                                </div>
                            ) : (
                                <div className="timeline">
                                    { timeline }
                                </div>
                            )}

                        </div>

                    </div>
                </div>

                <Footer />
            
            </div>
        </>
    )
}

const TimelineItem = ({ classSide, data }) => {
    return (
        <div className={"container " + classSide}>
            <div className="content">
                <h2>{ data.year }</h2>
                <p>{ data.body }</p>
            </div>
        </div>
    )
}

export default InfoGraphPage;