import React from 'react';

import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';

import video from '../../videos/movie.mp4';
import MainMenu from '../layouts/MainMenu';

const HomePage = () => {

    let imgSilamsakma = require('../../images/silam-sakma.png');

    return (
        <div className="page">

            <Header />

            <div className="content">

                <div className="content-wrapper">
                    <MainMenu />
                    

                    <div className="site-block-wrapper">

                        <div className="site-block">
                            <h4 id="home-culture-sub-title">My Limbu</h4>
                            <h1 id="home-culture-title">Culture</h1>
                            <div id="silam-sakma-logo">
                                <img src={imgSilamsakma} alt="silam sakma" />
                            </div>
                            <div className="statement-wrapper">
                                <div className="state-item">
                                    <div className="box-icon"></div>
                                </div>
                                <div className="state-item">
                                    <p className="home-state-motto">building strong community, spread knowledge</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div className="video-item">
                        <div className="video-wrapper">
                            <video autoPlay muted loop id="video-chyabrung" className="video">
                                <source src={video} type="video/mp4" />
                            </video>
                            <h3>Chyabrung Dance</h3>
                            <p>Chyabrung-lang a traditional drum of the Limbu community in Nepal.</p>
                        </div>
                    </div>

                    <div className="four-box-wrapper">
                        <div className="four-box">
                            <div className="four-box-item">
                                <Link to="/history" className="btn">
                                    History
                                </Link>
                            </div>
                            <div className="four-box-item">
                                <Link to="/timeline" className="btn">
                                    Timeline
                                </Link>
                            </div>
                            <div className="four-box-item">
                                <Link to="/blogs" className="btn">
                                    Blogs
                                </Link>
                            </div>
                            <div className="four-box-item">
                                <Link to="/events" className="btn">
                                    Events
                                </Link>
                            </div>
                            <div className="four-box-item">
                                <Link to="/gallery" className="btn">
                                    Gallery
                                </Link>
                            </div>
                            <div className="four-box-item">
                                <Link to="/credits" className="btn">
                                    Credits
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Footer />

        </div>
    )
}

export default HomePage;