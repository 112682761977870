import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import MainMenu from '../../layouts/MainMenu';
import { ImageWithFadeIn } from '../../../common/ImageWithFadeIn';

import { db } from '../../../common/firebase';
import {
    getDocs,
    collection
} from "firebase/firestore";

const Events = () => {
    let navigate = useNavigate();

    const [ events, setEvents ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const handleEventClick = (event) => {
        navigate('/events/' + event.id);
    }

    const blogMapped = events.map((event, key) => {

        const handleClick = () => {
            handleEventClick(event);
        }

        return (
            <Item key={key} event={event} handleClick={handleClick} />
        )
    })

    const getEvents = async () => {
        const ref = collection(db, 'festivals');
        const snapshot = await getDocs(ref);
        let data = snapshot.docs.map(doc => {
            let temp = doc.data();
            temp.id = doc.id;
            return temp;
        });

        setEvents(data);
        
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    useEffect(() => {
        getEvents();
    }, []);

    return (
        <>
            <div className="page page-events">

                <Header />

                <div className="content">
                    <div className="content-wrapper">

                        <MainMenu />

                        <div>
                            <h1 className="page-title">Events</h1>

                            <div className="blogs-wrapper">

                                {loading ? (
                                    <div className="loading-wrapper">

                                        <div className="loading">
                                            <div></div><div></div>
                                        </div>
                                        
                                    </div>
                                ) : (
                                    <div className="blogs-container">
                                        { blogMapped }
                                    </div>
                                )}
                            </div>

                        </div>

                    </div>
                </div>

                <Footer />

            </div>
        </>
    )
}

const Item = ({ event, handleClick }) => {
    return (
        <div className="blog-item" onClick={handleClick}>
            <div className="left-side">
                <ImageWithFadeIn src={event.image} />
            </div>
            <div className="right-side">
                <div className="title-section">
                    <h3>{ event.title }</h3>
                    <p>{ event.date }</p>
                </div>
                <div className="content-section">
                    <p>{ event.summary }</p>
                    <p>[ Read more ]</p>
                </div>
            </div>
        </div>
    )
}

export default Events;