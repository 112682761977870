const TopBar = () => {
    return (
        <div className="top-bar">

            <div className="top-bar-item top-bar-1"></div>

            <div className="top-bar-item top-bar-2"></div>

            <div className="top-bar-item top-bar-3"></div>

            <div className="top-bar-item top-bar-4"></div>

        </div>
    )
}

export default TopBar;