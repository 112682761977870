import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../../layouts/Header';
import MainMenu from '../../layouts/MainMenu';
import Footer from '../../layouts/Footer';

import { db } from '../../../common/firebase';
import {
	getDoc,
    doc
} from "firebase/firestore";

const EventPage = () => {
	const { id } = useParams();

	const [ event, setEvent ] = useState(null);
	const [ loading, setLoading ] = useState(true);

	const getEvent = async () => {
        const docRef = doc(db, "festivals", id);
        const docSnap = await getDoc(docRef);

        let event = null;
        if (docSnap.exists()) {
            event = docSnap.data();
        }

        setEvent(event);
        
		
		setTimeout(() => {
            setLoading(false);
        }, 500);
    }

	useEffect(() => {
		getEvent();
	}, []);

	return (
		<div className="page page-blogs">

			<Header />

			<div className="content">
				<div className="content-wrapper">

					<MainMenu />

					<div>
						
						{loading ? (
							<div className="loading-wrapper">

								<div className="loading">
									<div></div><div></div>
								</div>
								
							</div>
						) : (

							<>
								<h1 className="page-title">Festival - {event.title}</h1>

								<div className="event-wrapper">
									<p>{event.body}</p>
								</div>

							</>
						)}

					</div>

				</div>
			</div>

			<Footer />

		</div>
	)
}

export default EventPage;