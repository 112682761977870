import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import MainMenu from "../../layouts/MainMenu";

const GlossaryPage = () => {

    const onSearch = (e) => {
        console.log(e.target.value);
    }

    return (
        <div className="page page-glossary">
            <Header />
            
            <div className="content">
                    <div className="content-wrapper">

                        <MainMenu />

                        <div className="glossary-wrapper">
                            <h1 className="page-title">Glossary</h1>

                            {/* <div className="search-box">
                                <input type="text" onChange={onSearch} placeholder="search" />
                            </div> */}

                            <div className="content-a glossary-item">
                                <h2>[ A ]</h2>
                                <div className="item">
                                    <h3>Arun river</h3>
                                    <p>The Arun is the largest trans-Himalayan river passing through Nepal and also has the greatest snow and ice-covered area of any Nepalese river basin. The Arun drains more than half of the area contributing to the Sapta Kosi river system but provides only about a quarter of the total discharge.</p>
                                    <div className="link">
                                        <a href="https://en.wikipedia.org/wiki/Arun_River_(China%E2%80%93Nepal)" className="link-item link-wiki" target="_blank"></a>
                                        <a href="https://dbpedia.org/page/Arun_River_(China%E2%80%93Nepal)" className="link-item link-web" target="_blank"></a>
                                    </div>
                                </div>
                            </div>

                            <div className="content-k glossary-item">
                                <h2>[ K ]</h2>
                                <div className="item">
                                    <h3>Khas</h3>
                                    <p>Khas people, (English: / kɑːs /; Nepali: खस) popularly known as Khas Arya, [nb 1] are an Indo-Aryan ethno-linguistic group native to the Himalayan region of the Indian subcontinent, in what is now the present-day South Asian country of Nepal, as well as the Indian states of Uttarakhand, West Bengal and Sikkim.</p>
                                    <div className="link">
                                        <a href="https://en.wikipedia.org/wiki/Khas_people" className="link-item link-wiki" target="_blank"></a>
                                    </div>
                                </div>
                                <div className="item">
                                    <h3>Khukuri</h3>
                                    <p>Lorem ipsum dolor sit amet, pariatur et tempor quis ipsum tempor minim officia ipsum commodo dolor ad ut anim voluptate aliquip elit officia sit ad</p>
                                    <div className="link">
                                        <a href="https://en.wikipedia.org/wiki/Kukri" className="link-item link-wiki" target="_blank"></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </div>

            <Footer />
        </div>
    )
}

export default GlossaryPage;