const Footer = () => {
	return (
		<footer>
			<div className="credits-resources">
				<div className="left-side">
					<a href="#">Created by Nirmal Limbu</a>
				</div>
				<div className="right-side">
					<a href="#">© 2024-2025</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
