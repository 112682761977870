import React from 'react'
import Header from '../layouts/Header'
import MainMenu from '../layouts/MainMenu'

const NotFoundPage = () => {
  return (
    <>
        <div className="page page-not-found">
            <Header />
            
            <div className="content">
                <div className="content-wrapper">

                    <MainMenu />
                    
                    <h3 className="page-title-not-found">Page not found</h3>

                </div>
            </div>

        </div>
    </>
  )
}

export default NotFoundPage