import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import MainMenu from "../layouts/MainMenu";

const slidesDefault = [
    {
        'id': 1,
        'image': require('../../images/1.all.png'),
        'text': 'Tongba | Thumse'
    },
    {
        'id': 2,
        'image': require('../../images/2.tongba.png'),
        'text': 'Tongba'
    },
    {
        'id': 3,
        'image': require('../../images/3.thumse.png'),
        'text': 'Thumse'
    },
    {
        'id': 4,
        'image': require('../../images/4.jato.jpg'),
        'text': 'Jato'
    },
    {
        'id': 5,
        'image': require('../../images/5.vado-fasi.jpg'),
        'text': 'Vado - Fasi'
    },
    {
        'id': 6,
        'image': require('../../images/6.juwa-date-halo.jpg'),
        'text': 'Juwa - Date - Halo'
    },
    {
        'id': 7,
        'image': require('../../images/7.pang.jpg'),
        'text': 'Pang'
    },
    {
        'id': 8,
        'image': require('../../images/8.sapko.jpg'),
        'text': 'Sapko'
    },
    {
        'id': 9,
        'image': require('../../images/9.doko.jpg'),
        'text': 'Doko'
    },
    {
        'id': 10,
        'image': require('../../images/10.dalo.jpg'),
        'text': 'Dalo'
    },
    {
        'id': 11,
        'image': require('../../images/11.okhli-chapani.jpg'),
        'text': 'Okhli - Chapani'
    },
    {
        'id': 12,
        'image': require('../../images/12.purse.jpg'),
        'text': 'Purse'
    },
    {
        'id': 13,
        'image': require('../../images/13.firke.jpg'),
        'text': 'Firke'
    },
    {
        'id': 14,
        'image': require('../../images/14.chakra.jpg'),
        'text': 'Chakra'
    },
    {
        'id': 15,
        'image': require('../../images/15.muda.jpg'),
        'text': 'Muda'
    },
    {
        'id': 16,
        'image': require('../../images/16.chyabrung.jpg'),
        'text': 'Chyabrung'
    },
    {
        'id': 17,
        'image': require('../../images/17.tan.jpg'),
        'text': 'tan'
    },
    {
        'id': 18,
        'image': require('../../images/18.thakra.jpg'),
        'text': 'thakra'
    },
];

const GalleryPage = () => {

    const [ slides, setSlides ] = useState([]);
    const [ slideIndex, setSlideIndex ] = useState(1);

    const loadSlides = () => {
        const tempSlides = slidesDefault;

        setSlides(tempSlides);
    }

    const plusSlides = (value) => {
        const next = slideIndex + value;
        if (next <= 0)
            return;
        if (next > slides.length)
            return;
        setSlideIndex(slideIndex + value);
    }

    const currentSlide = (value) => {
        setSlideIndex(value);
    }

    useEffect(() => {
        loadSlides();
    }, []);

    return (
        <>
            <div className="page">

                <Header />

                <div className="content">
                    <div className="content-wrapper">

                        <MainMenu />

                        <h1 className="page-title">Gallery Page</h1>

                        {/* Slideshow container */}
                        <div className="slideshow-container">

                            <SlideContainer slides={slides} currentIndex={slideIndex} />

                            {/* Next and previous buttons */}
                            <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
                            <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
                        </div>

                        {/* The dots/circles */}
                        <div className="slider-paginator">
                            <span className={'dot active'} onClick={() => currentSlide(1)}></span>
                            <span className={'dot'} onClick={() => currentSlide(2)}></span>
                            <span className={'dot'} onClick={() => currentSlide(3)}></span>
                        </div>

                    </div>
                </div>

                <Footer />

            </div>
        </>
    )
}

const SlideContainer = ({ slides, currentIndex }) => {
    const slideItems = slides.map((item) => {
        return (
            <SlideItem slide={item} total={slides.length} key={item.id} currentIndex={currentIndex} />
        )
    });

    return (
        <>
            {slideItems}
        </>
    )
}

const SlideItem = ({ slide, total, currentIndex }) => {
    const displayStatus = (currentIndex == slide.id) ? 'block' : 'none';

    return (
        <div className="mySlides fade" key={slide.id} style={{ display: displayStatus }}>
            <div className="numbertext">{slide.id} / {total}</div>
            <LazyLoadImage src={slide.image} />
            <div className="text">{slide.text}</div>
        </div>
    );
}

export default GalleryPage;