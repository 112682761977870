import { useState } from "react";
import { Link } from "react-router-dom"

const MainMenu = () => {

    const [ menuActive, setMenuActive ] = useState(false);
    const [ menuClass, setMenuClass ] = useState('');

    const handleMenuClick = () => {
        let active = !menuActive;
        setMenuActive(active);
        if (active)
            setMenuClass('open');
        else
            setMenuClass('');
    }

    return (
        <>
            <nav className={"main-menu " + menuClass}>
                <Link to="/">
                    Home
                </Link>
                <Link to="/timeline">
                    Timeline
                </Link>
                <Link to="/blogs">
                    Blogs
                </Link>
                <Link to="/events">
                    Events
                </Link>
                <Link to="/glossary">
                    Glossary
                </Link>
                <Link to="/credits">
                    Credits
                </Link>
            </nav>

            <div className={"burger-menu " + menuClass} onClick={handleMenuClick}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>

        </>
    )
}

export default MainMenu;