import { useEffect, useState } from "react";

import { app, db } from '../../common/firebase';
import { getDoc, doc } from "firebase/firestore";
 
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import MainMenu from "../layouts/MainMenu";
import Map from "../blocks/Map";

const HistoryPage = () => {

    const [ page, setPage ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    const getHistoryPage = async () => {
        const docRef = doc(db, "pages", "jBlt4cb8oQpLWhf4MNu6");
        const docSnap = await getDoc(docRef);

        let historyPage = null;
        if (docSnap.exists()) {
            historyPage = docSnap.data();
        }
        
        setPage(historyPage);
        
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    useEffect(() => {
        getHistoryPage();
    }, []);

    return (
        <>
            <div className="page page-history">

                <Header />

                <div className="content">
                    <div className="content-wrapper">

                        <MainMenu />

                        {loading ? (
                            <div className="loading-wrapper">

                                <div className="loading">
                                    <div></div><div></div>
                                </div>
                                
                            </div>
                        ) : (
                            <>
                                <h1 className="page-title">{page.title}</h1>

                                <Map />

                                <div>
                                    <p>{page.body}</p>
                                </div>
                            </>
                        )}


                    </div>
                </div>

                <Footer />
            
            </div>
        </>
    )
}

export default HistoryPage;