import TopBar from "../blocks/TopBar";

const Header = ({ showViewIcon }) => {
    return (
        <>
            <TopBar />
            <header>

                {showViewIcon ? (
                    <div className="btn-video">
                        <img src={require('../../images/view.png')} />
                    </div>
                ) : (

                    <></>
                    
                )}
                

            </header>
        </>
    )
}

export default Header;